
import { IUserInfo } from '@core/models/auth/IDecodedToken.interface';
import { IUserState } from '@core/models/auth/IUserState.interface';

import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

const initialUserState: IUserState = {
  userInfo: null,
  isLogged: false,
  invalidCredentials: false,
  isLoading: false,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(initialUserState),
  withMethods(
    (
      store,

    ) => ({
      setUserInfo(newUserInfo: IUserInfo) {
        patchState(store, { userInfo: newUserInfo, isLogged: true });
      },
      resetUserInfo() {
        patchState(store, { userInfo: null, isLogged: false });
      },
      setUserLanguage(language: string) {
        if (!store.userInfo()) return;
        patchState(store, { userInfo: { ...store.userInfo()!, language } });
      },

    })
  )
);

// login() {},
//       recoverUserInfo() {
//         const refresh_token = localStorageService.getRefreshToken();
//         const auth_token = localStorageService.getAuthToken();
//         if (auth_token && auth_token.length > 0 && refresh_token && refresh_token.length > 0) {
//           const payload = localStorageService.decodeToken(auth_token);
//           patchState(store, { userInfo: payload.userInfo, isLogged: true });
//         }
//       },
//       logout() {
//         localStorageService.removeTokens();
//         router.navigate(['/login']);
//       }
